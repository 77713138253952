import axios from "axios";
import { toast } from "react-toastify";
import { config } from "./axiosconfig";
import { base_url } from "./baseUrl";

let pageData = {
  pageSize: 100,
  pageNumber: 1,
};

const requestConfig = { ...config };

if (pageData && pageData.pageSize && pageData.pageNumber) {
  requestConfig.params = {
    pageSize: pageData.pageSize,
    pageNumber: pageData.pageNumber,
  };
}

// Common function for the get APIs
const fetchData = async (endPoint) => {
  try {
    const response = await axios.get(`${base_url}${endPoint}`, requestConfig);
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error("Hold your breath, something went Wrong !!");
  }
};

// common function to get the data by id
const fetchDataById = async (endPoint, id) => {
  try {
    const response = await axios.get(
      `${base_url}${endPoint}/${id}`,
      requestConfig
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }

};

// To update the user profile
const updateUserProfile = async (values) => {
  const response = await axios.patch(
    `${base_url}updateUserProfile`,
    values,
    config
  );
  return response.data;
};

// To Send and Resend the OTP on fogot password page.
const sendOtp = async (data) => {
  const response = await axios.post(`${base_url}auth/forgot-password`, data);
  return response;
};

// To get the schools lists
const getSchools = async () => {
  const response = await axios.post(`${base_url}getSchool`, config);
  return response;
};
const getSchoolsBySchoolId = async (id) => {
  const response = await axios.post(`${base_url}getSchoolClassByschoolId/${id}`, config);
  return response;
};
const getSchoolClasses = async (id) => {
  const response = await axios.get(`${base_url}getSchoolClass`, config);
  return response;
};

// To get the total orders
const getOrders = async (data) => {
  const requestConfig = { ...config };
  if (data && data.pageSize && data.pageNumber) {
    requestConfig.params = {
      pageSize: data.pageSize,
      pageNumber: data.pageNumber,
      end: data.endDate || "",
      start: data.startDate || "",
    };
  }
  // if (data && data.startDate && data.endDate) {
  //     requestConfig.params = {
  //         end: data.endDate || '',
  //         start: data.startDate || '',
  //     }
  // }

  try {
    const response = await axios.get(`${base_url}getOrdersList`, requestConfig);
    return response;
  } catch (error) {
    console.log(error, "error");
    return error.response;
  }
};

// To get the total Transactions
const getTransactions = async (data) => {
  const requestConfig = { ...config };
  if (data && data.pageSize && data.pageNumber) {
    requestConfig.params = {
      pageSize: data.pageSize,
      pageNumber: data.pageNumber,
      end: data.endDate || "",
      start: data.startDate || "",
    };
  }
  // if (data && data.startDate && data.endDate) {
  //     requestConfig.params = {
  //         end: data.endDate || '',
  //         start: data.startDate || '',
  //     }
  // }

  try {
    const response = await axios.get(
      `${base_url}transaction/getlist`,
      requestConfig
    );
    return response;
  } catch (error) {
    console.log(error, "error");
    return error.response;
  }
};

// for adding the order in backend.
const addOrder = async (data) => {
  try {
    const response = await axios.post(`${base_url}addOrder`, data, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

//for canceling the order

const cancelOrder = async (id) => {
  try {
    const response = await axios.patch(
      `${base_url}cancelOrder/${id}`,
      {},
      config
    );
    return response;
  } catch (error) {
    console.log(error, "error");
    return error.response;
  }
};

// Adding the transaction
const addTransaction = async (data) => {
  try {
    let obj = {
      amount: String(data.amount),
    };
    const response = await axios.post(
      `${base_url}transaction/add`,
      obj,
      config
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

// create Payments in Quick Pay

const createPayment = async (data) => {
  try {
    const response = await axios.post(
      `${base_url}quickpay/initPayment`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
};

// funtion for the 3-D secure in quick pay
const get3DSecureUrl = async (data) => {
  try {
    const response = await axios.post(
      `${base_url}transaction/create_payment_3d`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
};

// Authorize payment in quick pay
const authorizePayment = async (data, cardData, amount) => {
  try {
    const bodyData = {
      payment_id: data?.data?.id,
      amount: String(amount),
      number: String(cardData.number),
      expiry: String(cardData.expiry),
      cvv: String(cardData.cvc),
    };
    const response = await axios.post(
      `${base_url}quickpay/authorizePayment`,
      bodyData,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// function to capture payments
const capturePayment = async (data, amount) => {
  try {
    const id = data?.data?.data?.id;
    const bodyData = {
      payment_id: id,
      amount: String(amount),
    };
    const response = await axios.post(
      `${base_url}quickpay/capturePayment`,
      bodyData,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// function after payment success store transection history
const storeTransaction = async (data, orderId) => {
  try {
    const bodyData = {
      transactionId: data?.data?.data?.id,
    };
    const response = await axios.put(
      `${base_url}transaction/update/${orderId}`,
      bodyData,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// function for changing the signed in user password
const changePassword = async (data) => {
  try {
    const response = await axios.put(
      `${base_url}auth/update-password`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// Function to resend the OTP for the users verification.
const reSendOtp = async (email) => {
  try {
    let obj = {
      email: email,
    };
    const response = await axios.post(
      `${base_url}auth/resend-otp`,
      obj,
      config
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

// function to send emails

const sendCustomMail = async ({ childDetails, values }) => {
  const { childName, schoolName, className } = childDetails;

  const emailContent = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Email Template</title>
      <style>
          body {
              font-family: Arial, sans-serif;
          }
          .container {
              margin: 20px;
          }
          .label {
              font-weight: bold;
          }
          .value {
              margin-bottom: 10px;
          }
      </style>
  </head>
  <body>
      <div class="container">
          <p><span class="label">Purpose of Inquiry: </span> ${values.option}</p>
          <p><span class="label">Name:</span> ${values?.name}</p>
          <p><span class="label">Email Address:</span> <a href="mailto:${values.email}">${values.email}</a></p>
          ${values.option === 'child' && childDetails
      ? `<p><span class="label">Child Name:</span> ${childName}</p>
                 <p><span class="label">School Name:</span> ${schoolName}</p>
                 <p><span class="label">Class:</span> ${className}</p>`
      : ''
    }
          <p class="value">${values.message}</p>
      </div>
  </body>
  </html>
  `
  const obj = {
    email: 'anurag.p@cisinlabs.com', // replace email with desired email
    subject: 'Inquery Mail',
    message: emailContent
  };
  const response = await axios.post(`${base_url}sendemailWtLogin`, obj).then((res) => {
    return res;
  }).catch((err) => {
    console.log(err, 'err');
  })

}

const getPageContent = async (id) => {
  const response = await axios.get(`${base_url}getPageById/${id}`, config);
  return response?.data;
}
const commonFunction = {
  updateUserProfile,
  sendOtp,
  getSchools,
  getOrders,
  getTransactions,
  addOrder,
  cancelOrder,
  addTransaction,
  createPayment,
  authorizePayment,
  capturePayment,
  storeTransaction,
  fetchData,
  fetchDataById,
  changePassword,
  reSendOtp,
  sendCustomMail,
  getPageContent,
  get3DSecureUrl,
  getSchoolsBySchoolId,
  getSchoolClasses
};

export default commonFunction;
