import React from "react";
import { useNavigate } from "react-router-dom";

function ProfileSide({
  openAccountModal,
  openPasswordModal,
  isOrder,
  addChild,
  onProfileTabClick,
}) {
  const navigate = useNavigate();
  return (
    <div className="col-md-3">
      <div className="tab_menuElements">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={isOrder || addChild ? "nav-link" : "nav-link active"}
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              <i className="fa fa-home" />
              <span className="targetLink">Home</span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              onClick={() => onProfileTabClick()}
            >
              <i className="fas fa-user-edit" />
              <span className="targetLink">My Profile</span>{" "}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={addChild ? "nav-link active" : "nav-link"}
              id="child-tab"
              data-bs-toggle="tab"
              data-bs-target="#child"
              type="button"
              role="tab"
              aria-controls="child"
              aria-selected="false"
            >
              <i className="fa fa-child" />
              <span className="targetLink">My Children</span>
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className={isOrder ? "nav-link active" : "nav-link"}
              id="order-tab"
              data-bs-toggle="tab"
              data-bs-target="#order"
              type="button"
              role="tab"
              aria-controls="order"
              aria-selected="false"
            >
              <i className="fa fa-shopping-cart" />
              <span className="targetLink">My Orders</span>
            </button>
          </li>

          <li className="nav-item bestil" role="presentation" style={{background:"#0ABB27" ,color:"white"}}>
            <button
              className="nav-link"
              id="bestil-tab"
              data-bs-toggle="tab"
              data-bs-target="#bestil"
              type="button"
              role="tab"
              aria-controls="bestil"
              aria-selected="false"
              style={{ color: "white" }}
              onClick={() => navigate('/make-order')}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
              <span className="targetLink">Bestil</span>
            </button>
          </li>

          <li
            className="nav-item my-transaction"
            role="presentation"
            style={{ background: "blue" }}
          >
            <button
              className="nav-link"
              id="transaction-tab"
              data-bs-toggle="tab"
              data-bs-target="#transaction"
              type="button"
              role="tab"
              aria-controls="transaction"
              aria-selected="false"
              style={{ color: "white" }}
            >
              <i className="fa fa-credit-card" aria-hidden="true"></i>
              <span className="targetLink">Tank Op</span>
            </button>
          </li>

          {/* Change Password */}

          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="changePass-tab"
              data-bs-toggle="tab"
              data-bs-target="#changePass"
              type="button"
              role="tab"
              aria-controls="changePass"
              aria-selected="false"
              onClick={() => {
                openPasswordModal();
              }}
            >
              <i className="fas fa-unlock-alt" />
              <span className="targetLink">Change Password</span>
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="closeacc-tab"
              data-bs-toggle="tab"
              data-bs-target="#closeacc"
              type="button"
              role="tab"
              aria-controls="closeacc"
              aria-selected="false"
              onClick={() => {
                openAccountModal();
              }}
            >
              <i className="fas fa-user-times" />
              <span className="targetLink">Close Account</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ProfileSide;
