import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { config } from '../../Utils/axiosconfig'
import { base_url } from '../../Utils/baseUrl';
import commonFunction from '../../Utils/commonFunction';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import OrderList from './orderList';
import { Link, useNavigate } from 'react-router-dom';
import { alertMessage, menuMessage } from '../../Utils/constants';
import Loader from '../Common/Loader';
import { getWalletAmount } from '../../app/wallet/wallet.slice';
import { userDetail } from '../../app/login/login.slice';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

function MakeOrder() {

    const [child, setChild] = React.useState(JSON.parse(localStorage.getItem('child'))); //Selected Child List if empty it will takes from the localstorage
    const [childlist, setChildlist] = useState(''); //List of all the childs

    const [hotMeals, setHotMeal] = useState({}); //List of all the hot meals
    const [showHotMeals, setShowHotMeals] = useState(false);

    const [foodCategory, setFoodCategory] = useState({}); //List of all the Food Category
    const [showFoodCategory, setShowFoodCategory] = useState(false);

    const [foodMenus, setFoodMenus] = useState({}); // List of all the food menus
    const [showfoodMenus, setShowFoodMenus] = useState(false); // List of all the food menus

    const [selectedMeal, setSelectedMeal] = useState(JSON.parse(localStorage.getItem('selectedMeal')) || []); // To select Hot Meal
    const [selectedFoodMeal, setSelectedFoodMeal] = useState(JSON.parse(localStorage.getItem('selectedFoodMeal')) || []); // For Selecting Food and beverages

    const [deleveryHotMealDate, setDeleveryHotMealDate] = useState(null); // Delevery date for Hot meal
    const [hotMealCalender, setHotMealCalender] = useState(false); // Get the value Hot meal calender

    const [foodCalender, setFoodCalender] = useState(false);//Open Food Calender
    const [deleveryFoodDate, setDeleveryFoodDate] = useState(null); // Delevery date for food and beverages meal

    const [lastInserted, setLastInserted] = useState(''); // Last Inserted Element
    const [hotMealDay, setHotMealDay] = useState(''); // for getting the selected hot meal day

    const [firstIndex, setFirstIndex] = useState(false);
    const [hotMealIndex, setHotMealIndex] = useState(false);

    // selected dates in calender
    const [selectedHotmealDates, setSelectedHotmealDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);

    const [customClass, setCustomClass] = useState('');

    // States for the loader in order list component
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Detect mobile view
    const userWalletAmount = useSelector((state) => state.wallet?.amount);
    const dispatch = useDispatch();
    const orderDetails = useSelector((state) => state.order?.order);

    const user = useSelector((state) => state.user);

    const style = {
        background: "#000000",
        color: '#ffffff',
        padding: '12px',
        borderRadius: '5px',
        fontSize: '13px',
    };
    const style2 = {
        color: 'white',
    }
    const walletStyle = {
        background: '#ee4d1a',
        padding: '7px',
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            dispatch(userDetail(config));
            dispatch(getWalletAmount());
        }
    }, [dispatch]);

    useEffect(() => {
        updateWalletAmount();
    }, []);

    const updateWalletAmount = async () => {
        try {
            await axios.get(`${base_url}transaction/completePaymentWalletUpdate`);
        } catch (error) {
            console.error("Error completing payment:", error);
        }
    };

    const handleChange = (event) => {
        setCustomClass('customClass');
        if (childlist) {
            const childname = childlist.find((item) => item.id === parseInt(event.target.value));
            setChild(childname);
            localStorage.setItem('child', JSON.stringify(childname));
        } else {
            navigate('/myprofile', {
                state: {
                    addChild: true
                }
            });
        }
    };

    // Disable Dates according to the hotMeal Days and disable today's date after 6 AM
    const tileDisabled = ({ date, view }) => {
        const today = new Date();

        // Check if the date is today
        const isToday =
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();

        // Check the current time
        const currentHour = today.getHours();
        const isAfter6AM = currentHour >= 6;

        // Disable today's date if it's after 6 AM
        if (isToday && isAfter6AM) {
            return true;
        }

        if (!hotMealDay) {
            return view === 'month' && (date.getDay() === 0 || date.getDay() === 6);
        }

        switch (hotMealDay) {
            case 'Mon':
                return date.getDay() !== 1;
            case 'Tue':
                return date.getDay() !== 2;
            case 'Wed':
                return date.getDay() !== 3;
            case 'Thu':
                return date.getDay() !== 4;
            case 'Fri':
                return date.getDay() !== 5;
            default:
                return date.getDay() === 0 || date.getDay() === 6;
        }
    };
    // Disable weekends and today's date after 6 AM
    const tileDisabledFood = ({ date, view }) => {
        if (view === 'month') {
            const today = new Date();
            // Disable weekends (Saturday and Sunday)
            const isWeekend = date.getDay() === 0 || date.getDay() === 6;

            // Check if the date is today
            const isToday =
                date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear();

            // Check the current time
            const currentHour = today.getHours();
            const isAfter6AM = currentHour >= 6;

            // Disable today's date only after 6 AM
            return isWeekend || (isToday && isAfter6AM);
        }
    };

    const handelHotMeal = async () => {
        if (lastInserted && (lastInserted.date === null || !lastInserted.hasOwnProperty('date'))) {
            alert(alertMessage);
            return;
        }

        if (Object.keys(hotMeals).length === 0) {
            const hotMeal = await commonFunction.fetchData('getHotMeal');
            setHotMeal({ data: hotMeal });
        }
        setShowFoodCategory(false);
        showHotMeals === true ? setShowHotMeals(false) : setShowHotMeals(true);
        setFoodCalender(false);
        setShowFoodMenus(false);
        setSelectedDates([]);
    };

    // function when hotmeal clicked
    const handelSelectedMeal = async (data) => {
        setHotMealIndex(true);
        // if (!selectedMeal.some(item => item.id === data.id)) {
        //     const newData = { ...data, date: deleveryHotMealDate, quantity: 1 };
        //     setSelectedMeal((prevSelectedMeals) => [...prevSelectedMeals, newData]);
        //     // localStorage.setItem('selectedMeal', JSON.stringify(selectedMeal));
        // }

        // Create a unique identifier for the new item (you can use timestamp)
        const uniqueId = new Date().getTime();
        const newData = { ...data, idMenu: uniqueId, quantity: 1 };

        // Add the new item to the selectedFoodMeal array
        setSelectedMeal(prevSelectedFoodMeals => [...prevSelectedFoodMeals, newData]);

        setLastInserted(newData);
        setHotMealCalender(true)
        setHotMealDay(data.hotMealDay);
        setShowHotMeals(false);
    }

    // This function handel Food and Beverages
    const handleFoodItem = async () => {
        if (lastInserted && (lastInserted.date === null || !lastInserted.hasOwnProperty('date'))) {
            alert(alertMessage);
            return;
        }
        if (Object.keys(foodCategory).length === 0) {
            const foodBeverages = await commonFunction.fetchData('getCategories');
            setFoodCategory({ data: foodBeverages });
        }
        showFoodCategory === true ? setShowFoodCategory(false) : setShowFoodCategory(true);
        setShowFoodMenus(false);
        setShowHotMeals(false);
        setHotMealCalender(false);
        setSelectedHotmealDates([]);
    }

    const handleSelectedCategory = async (data) => {
        setShowHotMeals(false);
        const menusByCategory = await commonFunction.fetchDataById('getMenuByCategory', data.idCategory)
        setFoodMenus(menusByCategory);
        setShowFoodCategory(false);
        setShowFoodMenus(true);
    }

    const handleSelectedFoodMeal = (data) => {

        setFirstIndex(true);
        // Check if the item already exists in the selectedFoodMeal array
        // if (!selectedFoodMeal.some(item => item.idMenu === data.idMenu)) {
        //     const newData = { ...data, date: deleveryFoodDate, quantity: 1 };
        //     setSelectedFoodMeal((prevSelectedFoodMeals) => [...prevSelectedFoodMeals, newData]);
        // }
        // setLastInserted(data);
        // setFoodCalender(true);
        // setShowFoodMenus(false);

        // Create a unique identifier for the new item (you can use timestamp)
        const uniqueId = new Date().getTime();
        const newData = { ...data, id: uniqueId, quantity: 1 };

        // Add the new item to the selectedFoodMeal array
        setSelectedFoodMeal(prevSelectedFoodMeals => [...prevSelectedFoodMeals, newData]);

        setLastInserted(newData);
        setFoodCalender(true);
        setShowFoodMenus(false);

    }

    // This function handels the hot meal date selected
    const handleHotMealCalender = async (value, event) => {
        if (!lastInserted) {
            alert(menuMessage);
            return;
        }
        setDeleveryHotMealDate(value);
        const dateString = value.toDateString();

        // Toggle date selection
        if (selectedHotmealDates.includes(dateString)) {
            setSelectedHotmealDates(selectedHotmealDates.filter(d => d !== dateString));
            const target = event.currentTarget;

            // Add only the desired classes
            setTimeout(() => {
                target.className = '';
                target.classList.add('react-calendar__tile', 'react-calendar__month-view__days__day');
            }, 100);
        } else {
            setSelectedHotmealDates([...selectedHotmealDates, dateString]);
        }

        if (hotMealIndex) {
            setSelectedMeal(prevSelectedMeals => {
                if (prevSelectedMeals.length > 0) {
                    const updatedMeals = [...prevSelectedMeals];
                    const lastIndex = updatedMeals.length - 1; // Get the index of the last item
                    if (!updatedMeals[lastIndex].hasOwnProperty('date')) {
                        updatedMeals[lastIndex].date = value; // Add the date if it doesn't exist
                    } else {
                        updatedMeals[lastIndex].date = value; // Update the date of the last item
                    }
                    return updatedMeals;
                }
                return prevSelectedMeals;
            });
            setHotMealIndex(false);
        } else {
            const newDate = new Date(value);
            let itemRemoved = false;

            const updatedSelectedMeal = selectedMeal.filter(food => {
                const existingDate = new Date(food.date);
                if (existingDate.getDate() === newDate.getDate() && existingDate.getMonth() === newDate.getMonth() && lastInserted.menuId === food.menuId) {
                    itemRemoved = true;
                    // setHotMealCalender(false);
                    return false; // Remove the item by filtering it out
                }
                return true;
            });

            if (!itemRemoved) {
                const uniqueId = new Date().getTime();
                const newData = { ...lastInserted, idMenu: uniqueId, date: value, quantity: 1 };

                setSelectedMeal(prevSelectedMeals => {
                    // Add the new data to the existing selectedMeal array
                    return [...prevSelectedMeals, newData];
                });
            } else {
                setSelectedMeal(updatedSelectedMeal); // Update the state with the filtered array
            }
        }

    }

    // this function handles the change date in Food and beverages section
    const handleFoodCalender = (value, event) => {
        if (!lastInserted) {
            alert(menuMessage);
            return;
        }
        const dateString = value.toDateString();

        // Toggle date selection
        if (selectedDates.includes(dateString)) {
            setSelectedDates(selectedDates.filter(d => d !== dateString));
            const target = event.currentTarget;

            // Add only the desired classes
            setTimeout(() => {
                // target.className = '';
                // target.classList.remove('react-calendar__tile--active', 'selected');
            }, 100);
        } else {
            setSelectedDates([...selectedDates, dateString]);
        }
        if (firstIndex) {
            setSelectedFoodMeal(prevSelectedMeals => {
                if (prevSelectedMeals.length > 0) {
                    const updatedMeals = [...prevSelectedMeals];
                    const lastIndex = updatedMeals.length - 1; // Get the index of the last item
                    updatedMeals[lastIndex].date = value; // Update the date of the last item
                    return updatedMeals;
                }
                return prevSelectedMeals;
            });
            setFirstIndex(false);
        } else {
            const newDate = new Date(value);
            const existingData = selectedFoodMeal.filter(food => {
                const existingDate = new Date(food.date);
                return (existingDate.getDate() === newDate.getDate() && existingDate.getMonth() === newDate.getMonth() && lastInserted.idMenu === food.idMenu);
            });

            if (existingData.length > 0) {
                const target = event.currentTarget;

                // Add only the desired classes
                setTimeout(() => {
                    target.className = '';
                    target.classList.add('react-calendar__tile', 'react-calendar__month-view__days__day');
                }, 200);
                // Remove the food item when the date matches.
                setSelectedFoodMeal(prevSelectedFoodMeals => prevSelectedFoodMeals.filter(food => {
                    const existingDate = new Date(food.date);
                    return !(existingDate.getDate() === newDate.getDate() && existingDate.getMonth() === newDate.getMonth() && lastInserted.idMenu === food.idMenu);
                }));
                // setFoodCalender(false);
            } else {
                const target = event.currentTarget;

                // Add only the desired classes
                setTimeout(() => {
                    target.className = '';
                    target.classList.add('react-calendar__tile', 'react-calendar__tile--active', 'react-calendar__tile--range', 'react-calendar__tile--rangeStart', 'react-calendar__tile--rangeEnd', 'react-calendar__tile--rangeBothEnds', 'react-calendar__month-view__days__day', 'selected');
                }, 200);
                const uniqueId = new Date().getTime();
                const newData = { ...lastInserted, id: uniqueId, date: value, quantity: 1 };

                setSelectedFoodMeal((prevSelectedFoodMeals) => {
                    // Add the new data to the existing selectedFoodMeal array
                    return [...prevSelectedFoodMeals, newData];
                });
            }
        }
        // setDeleveryFoodDate(value);
        // setSelectedFoodMeal((prevSelectedFoodMeals) => {
        //     const lastMealIndex = prevSelectedFoodMeals.length - 1;

        //     if (lastMealIndex >= 0) {
        //         prevSelectedFoodMeals[lastMealIndex].date = value;
        //     }

        //     // setFoodCalender(false);
        //     return [...prevSelectedFoodMeals];
        // });


        setDeleveryFoodDate(value);
    }

    useEffect(() => {
        try {
            axios.get(`${base_url}getChild`, config).then((res) => {
                setChildlist(res.data.data.child);

            }).catch((err) => {
                console.log(err);
            });
        } catch (error) {
            console.error(error);
        }
        if (child) {
            setCustomClass('customClass')
        }
    }, []);

    const tileClassName = ({ date, view }) => {
        if (selectedDates.includes(date.toDateString())) {
            return 'selected';
        }
        return null;
    };

    const tileHotmealClassName = ({ date, view }) => {
        if (selectedHotmealDates.includes(date.toDateString())) {
            return 'selected';
        }
        return null;
    };

    // This function handles the reset child section when the meal list is empty
    const handleResetChild = () => {
        setChild(null);
        localStorage.removeItem('child');
        setCustomClass('')
    }


    if (loader) {
        return <Loader />;
    }
    return (
        <>
            <section className="page-info-sec">
                <div
                    className="bg-fixed wow fadeIn"
                    data-wow-delay="0.1s"
                    style={{ background: 'url("assets/img/page-info1.png")' }}
                />
                <div className="bg-fixed img-overlay wow fadeIn" data-wow-delay="0.2s" />
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-12">
                            <div className="page-info-block wow fadeInUp" data-wow-delay="0.3s">
                                <h1>Mad Menu</h1>
                                <div className="btm-line-2 text-center justify-content-center">
                                    <span />
                                    <span />
                                </div>
                                <p>
                                    All Our <u> Ham</u>, <u>Pepperoni</u> and <u>Bacon</u> are <u>Halal</u> Turkey/Veal
                                </p>
                                <p>
                                    Select and schedule healthy, delicious meals for your children with ease. <br />
                                    Our Mad Menu ensures every bite is nutritious and kid-friendly, delivered right to your child.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sec-pd rel-z-1">
                <div className="top-bottom-shaps">
                    <span className="shap shap-1" />
                    <span className="shap shap-2" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div
                                className="title-1 title-center wow fadeInUp"
                                data-wow-delay="0.2s"
                            >
                                {/* <h1>Our Mad Menu</h1>
                                <div className="btm-line-1 text-center justify-content-center">
                                    <span />
                                    <span />
                                </div> */}
                                {/* <p>
                                    Select and schedule healthy, delicious meals for your children with ease. <br />
                                    Our Mad Menu ensures every bite is nutritious and kid-friendly, delivered right to your child.
                                </p> */}
                                {isMobileView && user?.user?.status ? (
                                    <span style={style}>
                                        <span style={style2}>

                                            {orderDetails?.totalQuantity || 0} pieces DKK {orderDetails?.totalPrice || 0}
                                        </span>
                                        <span style={walletStyle} className="m-3">
                                            My Balance: {userWalletAmount.wallet} Kr
                                        </span>

                                    </span>
                                ) : ('')}
                            </div>
                        </div>
                    </div>
                    <div className="wow fadeInUp">
                        <div className={customClass ? `${customClass} container border border-2` : "container border border-2"}>
                            <div className="menu-desc row">
                                <div className="select-child col-md-5">
                                    <span>
                                        <h4 className='p-3'>
                                            {child ? 'Selected Child for Order' : 'Select Child for Order'}
                                        </h4>
                                    </span>
                                    <span>
                                        {child ?
                                            <div className='d-flex'>

                                                <h4>
                                                    {child.name}
                                                </h4>
                                                {
                                                    selectedMeal.length === 0 && selectedFoodMeal.length === 0 && (<Link style={{ paddingLeft: '11px' }} onClick={handleResetChild}>Rest Child</Link>)
                                                }
                                            </div>
                                            :
                                            <select defaultValue='default' onChange={handleChange} style={{ borderRadius: '10px' }} className="w-auto mt-2 form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                                                <option>Select child</option>
                                                {(childlist !== undefined && childlist.length > 0) ?
                                                    childlist.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    }) :
                                                    <option>No child found</option>
                                                }
                                            </select>
                                        }
                                    </span>
                                    <br />

                                </div>
                                <div className='justify-content-between col-md-7 d-flex mt-2 mb-2'>
                                    <div className='hot-meals'>
                                        {
                                            child && !child.SchoolClass ? (
                                                <p className="no-class-message">This child has no class assigned. Please add a class.</p>
                                            ) : (
                                                <>
                                                    {
                                                        child && (
                                                            <>
                                                                <span></span>
                                                                <button className='btn btn-1' onClick={handelHotMeal}>hot meals</button>
                                                            </>
                                                        )
                                                    }

                                                    <div>
                                                        {showHotMeals && (
                                                            <div className='mb-2'>
                                                                {hotMeals?.data?.data?.HotMeallist.map((item, index) => (
                                                                    <div key={index}>
                                                                        <br />
                                                                        <button className='btn btn-1' onClick={() => { handelSelectedMeal(item) }}>{item.Menu.title}</button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div>
                                        {
                                            child && child.SchoolClass && (
                                                <>
                                                    {/* Add Back button for switching back to the category */}
                                                    {showFoodCategory || showfoodMenus && (
                                                        <span onClick={handleFoodItem} style={{ paddingRight: '18px', cursor: 'pointer' }}>
                                                            <i className="fa fa-reply" title='Go back to category' />
                                                        </span>
                                                    )}
                                                    <button className='btn btn-1' onClick={handleFoodItem}>Food & Beverages</button>
                                                    <div className='food-baverages' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        {showFoodCategory && (
                                                            <div className='mb-2'>
                                                                {foodCategory?.data?.data?.categories.map((item, index) => (
                                                                    <div key={index}>
                                                                        <br />
                                                                        <button className='btn btn-1' onClick={() => handleSelectedCategory(item)}>
                                                                            {item.title}
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* Show Food Menus by category */}
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        {showfoodMenus && (
                                                            <div className='mb-2'>
                                                                {(foodMenus?.data?.menus !== undefined && foodMenus?.data?.menus.length > 0) ? foodMenus?.data?.menus.map((item, index) => (
                                                                    <div key={index}>
                                                                        <br />
                                                                        <button className='btn btn-1' onClick={() => handleSelectedFoodMeal(item)}>
                                                                            {item.title}
                                                                        </button>
                                                                    </div>
                                                                )) : <p>No Menu found for this category</p>}
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                                {/* Calender for Hot meal and food */}
                                <div className='calender-div mt-2'>

                                    {/* message For the food and Beverages */}
                                    {
                                        selectedFoodMeal.length !== 0 && lastInserted && lastInserted.length !== 0 && lastInserted.Menu === undefined && (
                                            <span className='mb-2'>
                                                Choose the days you want <b style={{ backgroundColor: 'yellow' }}> {lastInserted.title} </b> Delivered.
                                            </span>
                                        )
                                    }
                                    {/* Message for the hot meals */}
                                    {
                                        selectedMeal.length !== 0 && lastInserted && lastInserted.Menu !== undefined && (
                                            <span className='mb-2'>
                                                Choose the days you want <b style={{ backgroundColor: 'yellow' }}> {lastInserted.Menu.title} </b> Delivered.
                                            </span>
                                        )
                                    }
                                    {
                                        child && hotMealCalender && (
                                            <Calendar
                                                onChange={(value, event) => handleHotMealCalender(value, event)}
                                                tileDisabled={tileDisabled}
                                                value={deleveryHotMealDate}
                                                minDate={new Date()}
                                                tileClassName={tileHotmealClassName}
                                                next2Label={null} // Removes the double right arrow
                                                prev2Label={null}
                                            />
                                        )
                                    }
                                    {
                                        child && foodCalender && (
                                            <Calendar
                                                onChange={(value, event) => handleFoodCalender(value, event)}
                                                tileDisabled={tileDisabledFood}
                                                value={deleveryFoodDate}
                                                minDate={new Date()}
                                                tileClassName={tileClassName}
                                                next2Label={null} // Removes the double right arrow
                                                prev2Label={null}
                                            />
                                        )
                                    }
                                </div>
                                <OrderList
                                    hotMeal={selectedMeal}
                                    selectedFoodMeal={selectedFoodMeal}
                                    setSelectedFoodMeal={setSelectedFoodMeal}
                                    setHotMeal={setSelectedMeal}
                                    child={child}
                                    setChild={setChild}
                                    handleHotMealCalender={handleHotMealCalender} // Pass the date selection handling function for hot meals
                                    handleFoodCalender={handleFoodCalender}
                                    lastInserted={lastInserted}
                                    alertMessage={alertMessage}
                                    setLastInserted={setLastInserted}
                                    setSelectedDates={setSelectedDates}
                                    selectedDates={selectedDates}
                                    setLoader={setLoader}
                                    setHotMealCalender={setHotMealCalender}
                                    setFoodCalender={setFoodCalender}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MakeOrder;