import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import { Input, Space } from "antd";
import * as yup from "yup";
import commonFunction from "../../Utils/commonFunction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const WalletModal = ({
  handleOpen,
  handleClose,
  remainingAmount,
  addMoreAmount,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    amount: yup
      .number()
      .required("Amount is required")
      .max(1000, "Amount cannot exceed 1000 kr"),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
    },

    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Step 1: Add transaction to the system
        const response = await commonFunction.addTransaction(values);

        if (response.status) {
          const transactionId = response?.data?.id;

          // Step 2: Create payment request with 3D Secure
          const paymentObj = {
            currency: "DKK",
            id: transactionId,
            amount: values?.amount,
          };

          await commonFunction.get3DSecureUrl(paymentObj).then((res) => {
            if (res?.status === true && res?.data?.url) {
              // Redirect to the 3D Secure URL
              window.location.href = res?.data?.url;
            } else {
              toast.error("Failed to create 3D Secure URL");
            }
          }).catch((error) => {
            console.error("Payment error:", error);
            toast.error("Failed to create payment");
          });
        } else {
          toast.error(response.message || "Failed to add transaction");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An unexpected error occurred. Please try again later.");
      } finally {
        setLoading(false);
        formik.resetForm();
      }
    },
    validationSchema,
  });

  return (
    <div>
      <Modal
        open={handleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Insufficient Wallet Amount
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please recharge your wallet to place this order.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            component="h2"
          >
            You need {addMoreAmount} kr. more to place this order
          </Typography>

          <br />
          <div className="col-md-12">
            <form onSubmit={formik.handleSubmit}>
              <Space.Compact style={{width:"100%"}}>
                <Input
                  placeholder="Please Enter amount"
                  type="number"
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  min={1}
                  max={1000}
                  disabled={loading}
                />
                <button
                  type="submit"
                  style={{ width: "65%" }}
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Add Wallet"}
                </button>
              </Space.Compact>
              {formik.touched.amount && formik.errors.amount ? (
                <div className="error-message">{formik.errors.amount}</div>
              ) : null}
            </form>
          </div>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "gray" }}
          >
            Note: Maximum allowable wallet top-up is 1000 kr.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default WalletModal;
