import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userDetail, userLogout } from '../../app/login/login.slice';
import { config } from '../../Utils/axiosconfig';
import Cookies from 'js-cookie';
import { getWalletAmount } from '../../app/wallet/wallet.slice';
import { Avatar } from '@mui/material';
import Loader from './Loader';
import { base_url } from '../../Utils/baseUrl';
import axios from 'axios';

export const Navbar = ({ navActive, setNavActive }) => {

    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const orderDetails = useSelector((state) => state.order?.order);
    const fullName = user?.user?.data?.firstName + ' ' + user?.user?.data?.lastName;
    const location = useLocation();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Detect mobile view

    const style = {
        background: "#000000",
        color: '#ffffff',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '15px'
    }
    const walletStyle = {
        background: '#ee4d1a',
        padding: '5px',
    }
    const newListStyle = {
        padding: '10px 100px',
        background: 'rgba(238, 77, 26, 0.19)',
        height: '40px',
        lineHeight: '1',
        fontWeight: '500',
        fontSize: '20px',
    }


    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            dispatch(userDetail(config));
            // dispatch(getWalletAmount());

            const interval = setInterval(() => {
                dispatch(getWalletAmount());
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [dispatch]);

    const handleLogout = async () => {
        setLoader(true);
        Cookies.remove('token');
        localStorage.clear(); // clear all the local storage
        const res = await dispatch(userLogout(config));
        if (res?.payload?.status === true) {
            await navigate('/login');
            setLoader(false);
        }
    }

    const userWalletAmount = useSelector((state) => state.wallet?.amount);

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: '#ee4d1a',
            },
            children: `${name.split(' ')[0][0] || ''}${name.split(' ')[1][0] || ''}`,
        };
    }

    // const handleToggle = async() => {        
    //     setNavActive(false);
    //     if (navActive) {
    //         document.body.classList.remove('menu-show');
    //     } else {
    //         document.body.classList.add('menu-show');
    //     }
    // }
    // if (loader) {
    //     return <Loader />;
    // }

    useEffect(() => {
        updateWalletAmount();
    }, []);


    const updateWalletAmount = async () => {
        try {

            const response = await axios.get(`${base_url}transaction/completePaymentWalletUpdate`);
        } catch (error) {
            console.log('error');
            console.error("Error completing payment:", error);
        }
    }

    const handleToggle = async () => {
        setNavActive(false);

        if (navActive) {
            document.body.classList.remove('menu-show');
        } else {
            document.body.classList.add('menu-show');
        }
    };

    if (loader) {
        return <Loader />;
    }

    const handleMyProfile = () => {
        navigate('/myprofile');
        window.location.reload();
    }

    return (
        <>
            <nav className="navbar navbar-expand-xl">
                <div className={navActive ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
                    {isMobileView && user?.user?.status ? (
                        <li style={newListStyle}>
                            <span className=" d-flex"
                                // to="/myprofile"
                                title={user?.user?.data?.firstName + ' ' + user?.user?.data?.lastName}
                            >
                                Welcome &nbsp; <Avatar {...stringAvatar(fullName)} className="profile-icon css-1qvqwae" />
                            </span>
                        </li>
                    ) : (
                        <>
                        </>
                    )}
                    <br />
                    <ul className="navbar-nav mr-auto">
                        {isMobileView && user?.user?.status ? (
                            <li className="nav-item" onClick={handleToggle}><NavLink className="nav-link" to="/">Frontpage</NavLink></li>
                        ) : (
                            <>
                            </>
                        )}
                        {!isMobileView && user?.user?.status ? (
                            <li className="nav-item" onClick={handleToggle}><NavLink className="nav-link" to="/">Home</NavLink></li>
                        ) : (
                            <>
                            </>
                        )}
                        {isMobileView && user?.user?.status ? (

                            <li className="nav-item" onClick={handleToggle}>
                                {/* <NavLink className="nav-link d-flex"
                                    // to="/myprofile" 
                                    onClick={handleMyProfile}
                                    title={user?.user?.data?.firstName + ' ' + user?.user?.data?.lastName}>
                                    Settings
                                </NavLink> */}
                                <NavLink
                                    className="nav-link d-flex"
                                    onClick={handleMyProfile}
                                    to={'/myprofile'}
                                >

                                    Settings
                                </NavLink>
                            </li>
                        ) : (
                            <>
                            </>
                        )}
                        <li className="nav-item" onClick={handleToggle}><NavLink className="nav-link" to="/about">About us</NavLink></li>
                        <li className="nav-item" onClick={handleToggle}><NavLink className="nav-link" to="/menu">Menu</NavLink></li>
                        <li className="nav-item" onClick={handleToggle}><NavLink className="nav-link" to="/faq">FAQ</NavLink></li>
                        <li className="nav-item" onClick={handleToggle}><NavLink className="nav-link" to="/contact">Contact us</NavLink></li>
                        <li className="nav-item" onClick={handleToggle}><NavLink className="nav-link" to="/terms">Terms & Conditions</NavLink></li>

                        {!isMobileView && user?.user?.status ? (
                            <li className="nav-item" onClick={handleToggle}>
                                <NavLink className="nav-link d-flex"
                                    to="/myprofile"
                                    onClick={handleMyProfile}
                                    title={user?.user?.data?.firstName + ' ' + user?.user?.data?.lastName}
                                >
                                    Welcome &nbsp; <Avatar {...stringAvatar(fullName)} className="profile-icon" />
                                </NavLink>
                            </li>
                        ) : (
                            <>
                            </>
                        )}


                        {!isMobileView && user?.user?.status ? (
                            <li className="nav-item" style={{ marginLeft: '0px' }}>
                                <span className="nav-link">
                                    <span style={style}>
                                        {orderDetails?.totalQuantity || 0} pieces DKK {orderDetails?.totalPrice || 0}
                                        <span style={walletStyle} className="m-2">
                                            My Balance: {userWalletAmount.wallet} Krs
                                        </span>
                                    </span>
                                </span>
                            </li>
                        ) : ('')}

                        {user?.user?.status ? (
                            <li className="nav-btn" style={{ marginLeft: 'auto' }}>
                                <>
                                    <button className="btn btn-1" onClick={handleLogout} >
                                        Logout
                                    </button>
                                </>
                            </li>
                        ) : (
                            <li className='nav-btn'>
                                <NavLink className="btn btn-1" onClick={handleToggle} to="/login" >
                                    Login
                                </NavLink>
                                <NavLink className="btn btn-dark" to="/signup" onClick={handleToggle}>
                                    Sign up
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>
        </>
    )
}