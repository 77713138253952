import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import commonFunction from '../../Utils/commonFunction';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import { base_url } from '../../Utils/baseUrl';
import { config } from '../../Utils/axiosconfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { fetchChild } from '../../app/child/childSlice';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('child name is required'),
  schoolId: Yup.string().required('child school is required'),
  classId: Yup.string().required('child class is required'),
});

const EditChild = ({ isOpen, closeModal, childId, school, userData }) => {

  const [childData, setChildData] = useState();
  const [classes, setClasses] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleGetChildById = async () => {
      if (childId) {
        const response = await commonFunction.fetchDataById('getChildById', childId);
        setChildData(response);
      }
    };

    handleGetChildById(); // Call the function immediately

  }, [childId]);

  const handleSubmit = async (values) => {

    try {
      await axios.patch(`${base_url}updateChild/${childId}`, values, config).then(res => {
        toast.success(res.data.message);
      }).catch(err => {
        console.log(err, 'err');
      })
    } catch (error) {
      console.error('An error occurred while updating profile:', error);
      toast.error('Please wait!! Something went wrong !!');
    }
    closeModal();
    dispatch(fetchChild(userData));
  };

  const formik = useFormik({
    initialValues: {
      name: childData?.data?.name || '',
      schoolId: childData?.data?.schoolId || '',
      classId: childData?.data?.classId || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

    // Fetch classes based on the selected school ID
    useEffect(() => {
      const fetchClasses = async () => {
        if (formik.values.schoolId) {
          try {
            const response = await axios.get(
              `${base_url}getSchoolClassByschoolId/${formik.values.schoolId}`,
              config
            );
            setClasses(response.data.data.schoolclass || []);
          } catch (error) {
            console.error('Error fetching classes:', error);
            setClasses([]);
          }
        } else {
          setClasses([]);
        }
      };
  
      fetchClasses();
    }, [formik.values.schoolId]);

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>Edit Child</DialogTitle>
      <DialogContent>
        <TextField
          id="name"
          label="Child's name"
          name="name"
          value={formik.values.name || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
          margin="normal"
        />

        <TextField
          select
          id="schoolId"
          label="Child's school"
          name="schoolId"
          value={formik.values.schoolId || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.schoolId && Boolean(formik.errors.schoolId)}
          helperText={formik.touched.schoolId && formik.errors.schoolId}
          fullWidth
          margin="normal"
        >
          {school?.data?.school.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.schoolName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          id='classId'
          label="Select class"
          name="classId"
          value={formik.values.classId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.classId && Boolean(formik.errors.classId)}
          helperText={formik.touched.classId && formik.errors.classId}
          fullWidth
          margin="normal"
        >
          {classes.length > 0 ? (
            classes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.className}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              No classes available
            </MenuItem>
          )}
        </TextField>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Cancel
        </Button>
        <Button onClick={formik.handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditChild;
